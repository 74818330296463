import { configureStore } from '@reduxjs/toolkit'
import counterReducer from '../features/counter/counterSlice'
import routeReducer from '../features/route/routeSlice'
import timeReducer from '../features/time/timeSlice'
import userReducer from '../features/user/userSlice'

export default configureStore({
  reducer: {
    counter: counterReducer,
    route: routeReducer,
    time: timeReducer,
    user: userReducer,
  },
})
