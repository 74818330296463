const api = {
  fromKirby: true,
  getApiUrl (url) {
    if (!this.fromKirby) {
      return `/assets/mockData/${url}`;
    }
    return url;
  },
  getAssetUrl (url, theme, fromBase) {
    if (!this.fromKirby) {
      if (!fromBase) {
        return `/assets/mockData/desktop/${theme ? `${theme}/` : '' }${url}`;
      }
      return url;
    }

    if (!fromBase) {
      return `/build/assets/mockData/desktop/${theme ? `${theme}/` : '' }${url}`;
    }
    return `/build/${url}`;
  },
  getGalleryUrl (url, projectId, resolution) {
    if (!this.fromKirby) {
      return `/assets/mockData/projects/${projectId}/${url}`;
    }
  },
  getProjectFile (projectId, resolution) {
    return `/assets/mockData/projects/${projectId}.png`;
  },
  getSpecialImage (specialId, resolution) {
    return `/assets/mockData/specials/${specialId}.webp`;
  }
};

export default api;
