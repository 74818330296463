import { createSlice } from '@reduxjs/toolkit'

export const timeSlice = createSlice({
  name: 'time',
  initialState: {
    night: false,
    nightOverride: false,
    time: new Date().getTime(), // in ms, Date is not serializable
    theme: 'henrike', // henrike
    videoPause: false,
  },
  reducers: {
    setNight: (state, action) => {
      state.night = action.payload
    },
    toggleNightOverride: (state) => {
      state.nightOverride = !state.nightOverride
    },
    setTime: (state, action) => {
      state.time = action.payload
    },
    toggleTheme: (state) => {
      state.theme = state.theme ? '' : 'henrike'
    },
    toggleVideoPause: (state) => {
      state.videoPause = !state.videoPause;
    },
    setVideoPause: (state, action) => {
      state.videoPause = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setNight, toggleNightOverride, setTime, toggleTheme, toggleVideoPause, setVideoPause } = timeSlice.actions

export const selectNight = (state) => state.time.nightOverride ? !state.time.night : state.time.night;
export const selectNightOriginal = (state) => state.time.night;
export const selectTime = state => state.time.time
export const selectTheme = state => state.time.theme
export const selectVideoPause = state => state.time.videoPause

export default timeSlice.reducer
