import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    language: 'en', // 'en' or 'de'
    fullscreen: false,
  },
  reducers: {
    toggleLanguage: (state) => {
      state.language = state.language === 'en' ? 'de' : 'en';
    },
    setFullscreen: (state, action) => {
      state.fullscreen = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { toggleLanguage, setFullscreen } = userSlice.actions

export const selectLanguage = (state) => state.user.language;
export const selectIsEnglish = (state) => state.user.language === 'en';
export const selectFullscreen = (state) => state.user.fullscreen;

export default userSlice.reducer
