import { h } from 'preact';
import { useCallback, useEffect } from 'preact/hooks';
import { Router, route } from 'preact-router';

// Components
import Header from './header';
import DesktopWrapper from '/routes/desktop';

// Code-splitting is automated for `routes` directory
import Home from '/routes/home';
import Profile from '/routes/profile';
import Henrike from '/routes/henrike';

// UTILS
import api from '/api';
import { SunCalc } from '/utils/time';

// STORE
import store from '/store'
import { Provider, useSelector, useDispatch } from 'react-redux'
import { route as routeFromSlice, selectPath } from '/features/route/routeSlice'
import { setNight, setTime, selectNight, selectTheme } from '/features/time/timeSlice'
import { toggleLanguage } from '/features/user/userSlice'

// CONFIG
const Config = {
  timeUpdateInterval: 1000, // in ms
  sunCalcLocation: [52.52, 13.41], // berlin
};

const App = () => {

  const dispatch = useDispatch();

  // STORE: ROUTE
  const routeFromSelector = useSelector(selectPath);
  useEffect(() => {
    // console.log('route from selector', routeFromSelector);
  }, [routeFromSelector]);
  const handleRoute = async (e) => {
    console.log('route change', e.url, e);
    if (!e.active.length) route('/');
    dispatch(routeFromSlice(e.url));
  };

  // STORE: THEME
  const theme = useSelector(selectTheme);
  useEffect(() => {
    api.theme = theme;
  }, [theme]);

  // STORE: LANGUAGE
  useEffect(() => {
    console.log(navigator.language);
    const language = navigator.language.split('-')[0]?.toLowerCase();
    if (language === 'de') {
      dispatch(toggleLanguage());
    }
  }, [dispatch]);


  // STORE: TIME & DARK THEME
  const night = useSelector(selectNight);
  // time update & dark theme via suncalc
  const updateTime = useCallback(() => {
    const newTime = new Date();
    dispatch(setTime(newTime.getTime()));
    const { goldenHourEnd, dusk } = SunCalc.getTimes(newTime, ...Config.sunCalcLocation);
    if (newTime < goldenHourEnd || newTime > dusk) {
      // dispatch(setNight(true));
    }
    else {
      // dispatch(setNight(false));
    }
  }, [dispatch]);
  useEffect(() => {
    updateTime();
    const interval = setInterval(() => {
      updateTime()
    }, Config.timeUpdateInterval);
    return () => {
      clearInterval(interval);
    }
  }, [updateTime]);
  // dark theme: prefers color-scheme
  useEffect(() => {
    // check and listen to prefers-color-scheme
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      dispatch(setNight(true));
    }
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      dispatch(setNight(event.matches));
    });
  }, [dispatch]);

  return (
    <div id="app" class={`${night && 'dark'} ${theme}`}>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      {/* <link href="https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&family=Inter:wght@700&family=Prompt:wght@600&family=Karla:wght@800&family=Kanit:wght@600&family=Krona+One&family=Work+Sans:wght@700&family=Rubik:wght@700&display=swap" rel="stylesheet" /> */}
      {/* <link href="https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&family=Inter:wght@500;700&display=swap" rel="stylesheet" /> */}
      <link href="https://fonts.googleapis.com/css2?family=Doto:wght@100..900&family=Share+Tech+Mono&display=swap" rel="stylesheet" />
      <main>
        <Router onChange={handleRoute}>
          <div path="/home">
            <Header />
            <Home />
          </div>
          <div path="/profile/">
            <Header />
            <Profile user="me" />
          </div>
          <div path="/profile/:user">
            <Header />
            <Profile />
          </div>
          <div path="/henrike">
            <Header />
            <Henrike />
          </div>

          <DesktopWrapper path="/:viewer?/:subpage?" />
        </Router>
      </main>
    </div>
  )
};

const StoreWrappedApp  = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

export default StoreWrappedApp;
