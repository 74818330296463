import { createSlice } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
  name: 'route',
  initialState: {
    path: '',
    history: [],
  },
  reducers: {
    route: (state, action) => {
      state.path = action.payload
      state.history = [ action.payload, ...state.history ];
    },
  },
})

// Action creators are generated for each case reducer function
export const { route } = counterSlice.actions

export const selectPath = state => state.route.path
export const selectHistory = state => state.route.history
export const selectLastSinglePath = state => {
  const lastIndex = state.route.history.findIndex(path => !path.split('/')[1] || !path.split('/')[2]);
  if (lastIndex === -1) return '/';
  return state.route.history[lastIndex];
};

export default counterSlice.reducer
