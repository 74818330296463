import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { Link } from 'preact-router/match';
import style from './style.css';

const Header = () => {
  useEffect(() => {
    // fetch('http://localhost/projects.json')
    // .then(response => response.json())
    // .then((projects) => {
    //   console.log('projects', projects);
    // })
  }, []);
  return (
    <header class={style.header}>
      <a href="/" class={style.logo}>
        <img src="../../assets/preact-logo-inverse.svg" alt="Preact Logo" height="32" width="32" />
        <h1>Preact CLI</h1>
      </a>
      <nav>
        <Link activeClassName={style.active} href="/">
          Home
        </Link>
        <Link activeClassName={style.active} href="/profile">
          Me
        </Link>
        <Link activeClassName={style.active} href="/profile/john">
          John
        </Link>
        <Link activeClassName={style.active} href="/henrike">
          Henrike
        </Link>
        <Link activeClassName={style.active} href="/desktop">
          Desktop
        </Link>
      </nav>
    </header>
  )
};

export default Header;
